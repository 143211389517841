import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    "container"
  ]
  static values = {
    gameId: String,
    url: String,
    defaultMapId: String
  }

  connect() {
    const $this = this;
    let observer = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        if (mutation.addedNodes?.length > 1 && mutation.addedNodes[0].data == "\n") {
          $this.setupDraggableTaskGroups();
          $this.setupTribeExclusionList();
          isLoadingEditor = false
          endLoading()
          $('.new_task_group').show()          
          break;
        }
      }
    })

    observer.observe(this.containerTarget, { childList: true, subtree: true, attributes: true})
  }

  setupDraggableTaskGroups() {
    const $this = this;
    $(".task_group_draggable").draggable({
      helper: 'clone',
      cancel: ".grabber",
      drag: function(e, ui) {
        updateCoordinates($(this), e, ui, false)
        $(ui.helper).css('width', `${ $(e.target).outerWidth() }px`)
      },
      stop: function(e, ui) {
        updateCoordinates($(this), e, ui, true)
      }
    })

    $(".task_group_table_cell").droppable({
      hoverClass: 'selected',
      accept: function(d) {

        // If current task group has location and adding a location specific, do not allow
        if (!$(this).hasClass('task_group_draggable') && $(d).hasClass('task-db-entry-location-specific')) {
          if ($(d).attr('location') != $(this).attr('location')) {
            return false;
          }
        }

        if (!$(this).hasClass('task_group_draggable') && $(d).hasClass('task_group_draggable')) {
          return false;
        }

        if ($(this).hasClass('task_group_draggable') && $(d).hasClass('task_group_draggable')) {
          return false;
        }

        if ($(d).hasClass('ui-sortable-helper')) {
          return false;
        }

        // Otherwise, allow
        return true;

      },
      drop: function(e, ui) {

      var hasFoundLocationInExistingTaskGroup = false;

      if (ui.draggable.hasClass('task-db-entry-location-specific') && $(this).hasClass('task_group_draggable')) {
        $.each($('.task_group_table_cell'), function(idx, item) {
          task_group_location = $(item).attr('location');
          if (task_group_location == ui.draggable.attr("location")) {

              // Don't allow addition of location to task group
              alert('That location is being used in another Task Group!');
              hasFoundLocationInExistingTaskGroup = true;
              return false;
          }
        });
      }

        if (!hasFoundLocationInExistingTaskGroup) {
          var taskGroupID = $(this).attr("id").replace('task_group_', '');
          var taskID = ui.draggable.attr("id").replace('task_db_', '');
          $this.taskDatabaseController.addExistingTaskToTaskGroup(taskGroupID, taskID);
        }
      }
    })

    $('#task_group_list_data .multiple_maps_task_groups_container').sortable({
      items: ".task_group_table_cell",
      handle: ".grabber",
      placeholder: "task-highlight",
      cursor: "move",
      stop: function (event, ui) {
        saveCurrentTaskGroupOrder();
      }
    });

    $('#task_group_list_data .task_groups_container').sortable({
      items: ".task_group_table_cell",
      handle: ".grabber",
      placeholder: "task-highlight",
      cursor: "move",
      tolerance: 'pointer',
      stop: function (event, ui) {
        saveCurrentTaskGroupOrder();
      }
    });
  }

  setupTribeExclusionList() {
    let tribeExclusionList = [];
    $.each($('.hidden_tribe_ids:checked'), function(index, item) {
      tribeExclusionList.push(parseInt($(item).val()));
    })
    $('.task_group_table_cell').each(function(index, item) {
      let match = true;
      let tribesData = $(item).attr("data-tribes").split(',');
      if (tribesData && tribesData.length > 0) {
        let tribes = tribesData.map(function(num) { return parseInt(num); });
        let matches = tribes.filter(function(n) {
          return tribeExclusionList.indexOf(n) !== -1;
        });
        match = (matches.length === tribesData.length);
      }
      $(item).toggle(!match);
    })
  }

  loadTaskGroupList(map_id, callback) {
    if (map_id == "stick") {
      const re = /map_id=\d+/;
      if (this.containerTarget.src.match(re) != null) {
        map_id = this.containerTarget.src.match(re)[0].replace("map_id=", "")
      } else {
        map_id = null;
      }
    } else if (map_id == null) {
      closeTaskGroups()
      if (callback) callback(null, null)
      return
    }
    const seqRe = /&sequenced=true/
    let sequencedTaskGroups = false
    if (this.containerTarget.src.match(seqRe) != null) {
      sequencedTaskGroups = true
    }
    startLoading()
    let data = jQuery.extend(true, {}, taskData)
    if (map_id != DEFAULT_MAP) {
      data['map_id'] = map_id
    }
    let urlString = this.urlValue + "?game_id=" + this.gameIdValue + "&time=" + moment().unix() + "&locale=" + window.selectedLocale
    if ($('.btn-sequenced').hasClass('is-selected') || sequencedTaskGroups) {
      data['sequenced'] = true
      urlString += "&sequenced=true"
    }
    data['locale'] = window.selectedLocale
    if (map_id && Number(map_id) != 0) {
      urlString += "&map_id=" + map_id
    }
    this.containerTarget.src = urlString;
    $('.new_task_group').show();
    $('#task_group_data').addClass('selected');
    $('#task_group_list_data .multiple_maps_task_groups_container').sortable('enable')
    $('#task_group_list_data .task_groups_container').sortable('enable')
    refreshHotspotAndTaskCounts()
    endLoading()
  }

  get taskDatabaseController() {
    return this.application.getControllerForElementAndIdentifier($("#task_database_data")[0], 'task-database')
  }
}